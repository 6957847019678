import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Dialog,
  DialogContent,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import ChallengeCard from '../components/ChallCard';
import { createClient } from '@supabase/supabase-js';
import "./Home.css";
import { parse } from 'path';
import Navbar from '../components/Navbar';
import ReactMarkdown from 'react-markdown'

const supabase = createClient(process.env.REACT_APP_URL || '', process.env.REACT_APP_KEY || '')

interface Challenge {
  id: number;
  title: string;
  description: string;
  points: number;
  category: string;
  flag: string;
  writeup: string;
  link: string;
  img: string;
}

const Home: React.FC = () => {

  const [shakeFlagInput, setShakeFlagInput] = useState(false);
  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [selectedChallenge, setSelectedChallenge] = useState<Challenge | null>(null);
  const [flag, setFlag] = useState('');

  useEffect(() => {
    
    async function fetchChallenges() {
      const { data, error } = await supabase.from('challenges').select('*');
      if (error) {
        console.error('Error fetching challenges:', error.message);
      } else {
        //order challenges inside the category alphabetically
        data.sort((a: Challenge, b: Challenge) => (a.title > b.title) ? 1 : -1);
        
        setChallenges(data);
      }
    }

    fetchChallenges();

  }, []); // Empty dependency array to ensure useEffect runs only once


  const handleChallengeClick = (challenge: Challenge) => {
    setSelectedChallenge(challenge);
  };

  const handleCloseModal = () => {
    setSelectedChallenge(null);
    setFlag('');
  };

  const handleFlagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFlag(event.target.value);
  };
  
  const handleFlagSubmit = () => {
    if (flag.trim() !== '') {
      const correctFlag = selectedChallenge?.flag;

      if (flag === correctFlag) {
        // Mark challenge as solved in localStorage
        localStorage.setItem(`solved-${selectedChallenge?.id}`, 'true');
        localStorage.setItem('score', (parseInt(localStorage.getItem('score') || '0') + selectedChallenge?.points! || 0).toString());

        handleCloseModal();
      } else {
        // Trigger the shaking animation
        setShakeFlagInput(true);
        setTimeout(() => {
          setShakeFlagInput(false);
        }, 500); // Reset the shaking after 500ms
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleFlagSubmit();
    }
  };

  // Organize challenges into categories
  const categories: string[] = [...new Set(challenges.map(challenge => challenge.category))];
  return (
    <>
    <Navbar score={parseInt(localStorage.getItem('score') || '0')} />
    <Container>
      {categories.map(category => (
        <div key={category} style={{margin: "30px 0"}}>
          <br/>
          <Typography variant="h4" style={{margin: "10px 0"}}>{category}</Typography>
          <Grid container spacing={2}>
            {challenges
              .filter(challenge => challenge.category === category)
              .map(challenge => (
                <Grid key={challenge.id} item xs={12} sm={6} md={4}>
                  <ChallengeCard
                    challenge={challenge}
                    onClick={() => handleChallengeClick(challenge)}
                    solved={localStorage.getItem(`solved-${challenge.id}`) === 'true'}
                    shakeFlagInput={shakeFlagInput}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
      ))}

    {/* Modal for displaying challenge details */}
    <Dialog open={selectedChallenge !== null} onClose={handleCloseModal} maxWidth="md" fullWidth>
      {selectedChallenge && (
        <DialogContent>
          <Typography variant="h4" style={{textAlign: "center", paddingTop: "60px"}}>{selectedChallenge.title}</Typography>
          <Typography style={{textAlign: "center", paddingBottom: "60px"}}>{selectedChallenge.points} XP</Typography>
          <Typography><ReactMarkdown>{selectedChallenge.description}</ReactMarkdown></Typography>
          
          {selectedChallenge.img && (
            <><br/><img src={selectedChallenge.img} style={{borderRadius: "5px"}} width={"300px"} height={"auto"} /></>
          )}
          {selectedChallenge.link && (
            <><br/><a href={selectedChallenge.link} target="_blank">Challenge Link</a></>
          )}
          {selectedChallenge.writeup && (
            <><br/><a href={selectedChallenge.writeup} target="_blank">View Challenge Solution</a></>
          )}
          <TextField
            label="Flag"
            variant="outlined"
            value={flag}
            onChange={handleFlagChange}
            onKeyDown={handleKeyDown}
            fullWidth
            margin="normal"
            style={{ animation: shakeFlagInput ? 'shake 0.5s' : 'none' }} // Apply the animation if shakeFlagInput is true
            disabled={localStorage.getItem(`solved-${selectedChallenge.id}`) === 'true'}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleFlagSubmit}
            disabled={localStorage.getItem(`solved-${selectedChallenge.id}`) === 'true'}
          >
            Submit Flag
          </Button>
        </DialogContent>
      )}
    </Dialog>
  </Container>
  </>
);
};

export default Home;