import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

interface NavbarProps {
  score: number;
}

const Navbar: React.FC<NavbarProps> = ({ score }) => {
  return (
    <AppBar position="sticky" style={{ backgroundColor: '#333' }}>
      <Toolbar>
        <Typography variant="h6" component={Link} to="/" style={{ textDecoration: 'none', color: 'white', flexGrow: 1 }}>
          CTF12
          <div style={{ marginLeft: 'auto' }}>
          <Typography variant="body1" color={"gold"}>{score} XP</Typography>
        </div>
        </Typography>
        
        <Button component={Link} to="https://admida0ui.de" color="inherit">
          admida0ui
        </Button>
        <Button component={Link} to="https://soter14.tech" color="inherit">
          SOter14
        </Button>
        <Button component={Link} to="https://securinets.tn" color="inherit">
          Securinets
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
