import React from 'react';
import { Card, CardContent, Typography, CardActionArea } from '@mui/material';

interface ChallengeCardProps {
  challenge: {
    id: number;
    title: string;
    description: string;
    points: number;
    category: string;
    flag: string;
    writeup: string;
    link: string;
    img: string;
    };
  onClick: () => void;
  solved: boolean;
  shakeFlagInput: boolean;
}

const ChallengeCard: React.FC<ChallengeCardProps> = ({ challenge, onClick, solved }) => {
  return (
    <Card style={{ backgroundColor: solved ? 'green' : 'black' }}>
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Typography variant="h5" style={{ color: solved ? 'lightgray' : 'white' }}>
            {challenge.title}
          </Typography>
          <Typography fontWeight="fontWeightLight">
            {challenge.points} XP
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card> 

  );
};

export default ChallengeCard;
